<template>
  <div class="user-tab-connection">
    <template v-for="groupItem in interviewCopy">
      <v-card class="mb-7">
        <v-card-text class="pb-2">

          <div class="groupItem">
            <h2 class="mb-2">{{groupItem.gruppe}}</h2>
            <template v-for="questionItem in groupItem.fragen">
              <div class="questionItem">
                <template v-if="questionItem.typ == 'input'">
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <strong>{{questionItem.frage}}</strong>
                    <v-text-field
                      v-model="questionItem.wert"
                      outlined
                      hide-details="auto"
                      dense
                    ></v-text-field>
                  </v-col>
                </template>
                <template v-else-if="questionItem.typ == 'textarea'">
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <strong>{{questionItem.frage}}</strong>
                    <v-textarea
                      v-model="questionItem.wert"
                      outlined
                      rows="5"
                      hide-details="auto"
                      dense
                    ></v-textarea>
                  </v-col>
                </template>
                <template v-else-if="questionItem.typ == 'select'">
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <strong>{{questionItem.frage}}</strong>
                    <v-select
                      v-model="questionItem.wert"
                      hide-details="auto"
                      outlined
                      dense
                      :items="questionItem.auswahlOptionenJson"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </template>
                <template v-else-if="questionItem.typ == 'radio'">
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <strong>{{questionItem.frage}}</strong>
                    <v-radio-group
                      v-model="questionItem.wert"
                      inline
                      hide-details="auto"
                    >
                      <v-radio
                        v-for="option in questionItem.auswahlOptionenJson"
                        :label="option.label"
                        :value="option.value"
                        hide-details="auto"
                        dense
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </template>
                <template v-else-if="questionItem.typ == 'checkbox'">
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <strong>{{questionItem.frage}}</strong>
                    <v-checkbox
                      v-for="(option,i) in questionItem.auswahlOptionenJson"
                      v-model="questionItem.wert[option.value]"
                      :label="option.label"
                      :value="option.value"
                      hide-details="auto"
                      dense
                    ></v-checkbox>
                  </v-col>
                </template>
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <v-btn
      v-if="preview == false"
      color="primary"
      @click="editInterviewCheck()"
    >
      Speichern
    </v-btn>
  </div>
</template>

<script>
import {ref, watch} from "@vue/composition-api"
export default {
  props: {
    jobTitle: {
      type: String,
      required: true,
    },
    interview: {
      type: Array,
      required: true,
    },
    preview: {
      type: Boolean,
      required: false,
    }
  },
  setup(props, {emit}){

    const interviewCopy = ref([]);
    interviewCopy.value = JSON.parse(JSON.stringify(props.interview))

    watch(() => props.interview, (newValue) => {
      interviewCopy.value = JSON.parse(JSON.stringify(newValue))
    });

    const editInterviewCheck = () => {
      emit("editInterviewCheck", interviewCopy.value)
    }

    return {
      interviewCopy,
      editInterviewCheck
    }
  },
}
</script>

<style>
.v-input--selection-controls,
.v-input--selection-controls-dense{
  margin-top: 0px;
}
.groupItem{
  margin-bottom: 20px;
}
</style>
