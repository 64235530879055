

export default function useHelper(){

  const resolveUserStatusVariant = status => {
    if (status == 0) return 'secondary'
    if (status == 1) return 'success'

    return 'primary'
  }
  const resolveUserStatusText = status => {
    if (status == 0) return 'Nein'
    if (status == 1) return 'Ja'

    return ''
  }

  return {
    resolveUserStatusVariant,
    resolveUserStatusText
  }
}
